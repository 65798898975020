export const members = [
  {"id": "yoruka", "name": "宮地 ヨルカ", "img": require('../assets/yoruka.png')},
  {"id": "keo", "name": "ケオ", "img": require('../assets/keo.png')},
  {"id": "domaze", "name": "どまぞ", "img": require('../assets/domazo.png')},
  {"id": "aste", "name": "アステ", "img": require('../assets/aste.png')},
]

export const memberProfile = {
"yoruka": {
    "name": "宮地 ヨルカ",
    "name_en": "Miyaci Yollca",
    "img": require('../assets/yoruka.png'),
    "profile": {
      "race": "シマウマ",
      "from": "出身地",
      "like": "好きなもの",
      "dislike": "嫌いなもの"
    },
    "intro": "これは、メンバー個人の自己紹介文です。句読点でいい感じに折り返されるので、長文を書いても多少は大丈夫です。",
    "color": "#ccff00",
    "twitter": "https://twitter.com/home",
    "youtube": "https://www.youtube.com/",
    "twitch": "https://www.twitch.tv/"
  },
  "keo": {
    "name": "ケオ",
    "name_en": "Keo",
    "img": require('../assets/keo.png'),
    "profile": {
      "race": "悪魔",
      "from": "出身地",
      "like": "好きなもの",
      "dislike": "嫌いなもの"
    },
    "intro": "これは、メンバー個人の自己紹介文です。句読点でいい感じに折り返されるので、長文を書いても多少は大丈夫です。",
    "color": "#dc143c",
    "twitter": "https://twitter.com/home",
    "youtube": "https://www.youtube.com/"
  },
  "domaze":{
    "name": "どまぞ",
    "name_en": "Domazo",
    "img": require('../assets/domazo.png'),
    "profile": {
      "race": "種族",
      "from": "出身地",
      "like": "好きなもの",
      "dislike": "嫌いなもの"
    },
    "intro": "これは、メンバー個人の自己紹介文です。句読点でいい感じに折り返されるので、長文を書いても多少は大丈夫です。",
    "color": "#00bfff",
    "twitter": "https://twitter.com/home",
    "youtube": "https://www.youtube.com/"
  },
  "aste":{
    "name": "アステ",
    "name_en": "Aste",
    "img": require('../assets/aste.png'),
    "profile": {
      "race": "種族",
      "from": "出身地",
      "like": "好きなもの",
      "dislike": "嫌いなもの"
    },
    "intro": "これは、メンバー個人の自己紹介文です。句読点でいい感じに折り返されるので、長文を書いても多少は大丈夫です。",
    "color": "#db63bb",
    "twitter": "https://twitter.com/home",
    "youtube": "https://www.youtube.com/"
  },
}

export const ptofileMap = {
  "race": "種族",
  "from": "出身地",
  "like": "好きなもの",
  "dislike": "嫌いなもの"
 }
